
import MaxWidth from '@/components/MaxWidth.vue';
import { Component, Vue } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import GButton from '@/components/gsk-components/GskButton.vue';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import * as API from '@/api/cross-charging.api';
import excel from 'vue-excel-export'
import { UserModule } from '@/store/modules/user.module';

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
Vue.use(excel)

@Component({
  components: {
    MaxWidth,
    DatePicker,
    GButton,
    excel
  },
})


export default class CrossChargingReporting extends Vue {
    public reportYear = '';
    public isInputValid = true;
    public errorMsg = ""
    public reportData = []

    public worksheetFields = {
     "Service Name": {
        field: 'serviceProperties.kong_service_name',
        callback: (value: string) => {
             return `${value.replace("_", "")}`;
        }
    },
    "Business Unit": 'businessUnitName',
    "Business Sub Unit": 'businessSubUnit',
    "Cost Center Name": 'budgetingSourceId',
    "Cost Center Owner": 'constCenterOwner',
    "Project Name": "projectName",
    "Project Owner": "ProjectOwner",
    "On Production From": "ServieCreateTimestamp",
    "On Production To": "ServiceDeleteTimestamp",
}

    get fileName() {
        return `Cross-charging-report-for-${this.reportYear ? this.reportYear : dayjs().year()}.xls`
    }

    dateLimit (date: any, currentValue: any) {
        const developerPortalStartDate = dayjs().year(2019);
        const calendarDate = dayjs(date);

       const afterTest =  calendarDate.isSameOrAfter(developerPortalStartDate, 'year');
       const beforeTest = calendarDate.isSameOrBefore(dayjs(), 'year')

       return afterTest && beforeTest ? false : true;
    }

    validateInput() {
        console.log("Validate input")
        console.log(this.reportYear)
        if (this.reportYear?.length > 0) {
            this.isInputValid = true;
            this.errorMsg = ''
        } else {
            this.isInputValid = false;
            this.errorMsg = 'The date field cannot be empty';
        }
    }

    async submit() {
        this.isInputValid = true;
        this.validateInput();
        if (!this.isInputValid) return;

        try {
        const resp = await (await API.getCrossChargingReportData({
            fromDate: `${this.reportYear}-01-01`, 
            toDate: `${dayjs().year().toString() === this.reportYear ? dayjs().add(1, 'day').format('YYYY-MM-DD') : this.reportYear + '-12-31'}`, 
            envId: 3,
            userId: UserModule.user.userId}))?.data;

        if (resp.length === 0) {
            console.log("No data found")
            this.errorMsg = "No data found for the selected year."
            this.reportData = [];
            this.isInputValid = false;
            return;
        }

        this.reportData = resp 

        } catch (e) {
            console.error(e)
        }
    }

    clearForm () {
        setTimeout(() => {
            this.reportData = [];
            this.reportYear = '';
        }, 1000);
    }
}
